<script setup lang="ts">
import { useFetch } from '@vueuse/core'
import VueTurnstile from 'vue-turnstile'
import InputPhoneNumber from '../components/InputPhoneNumber.vue'
import countries, { defaultCountry } from '../../ts/phone/countries'

import { match } from '~/ts/utils/match'
import { route } from '@/composables/route'
import { useRegisterDialog } from '~/ts/dialog'

const { isOpen, close, properties } = useRegisterDialog()

const currentStep = ref<'one' | 'two' | 'three' | 'source_at_registration'>('one')

const form = useForm({
	first_name: '',
	last_name: '',
	email: '',
	phone: {
		text: '',
		formattedText: '',
		e164: '',
		iso2: defaultCountry?.iso2 as string,
		dialCode: defaultCountry?.dialCode as string,
	},
	password: '',
	password_confirmation: '',
	turnstile_response: '',
})

const sourceAtRegistrationForm = useForm({
	source_at_registration: '',
})

function register() {
	form
		.transform((data) => ({
			...data,
			phone: data.phone.e164,
		}))
		.post(route('captainjet.web.register.store'), {
			preserveScroll: true,
			onSuccess: () => {
				form.cancel()
				currentStep.value = 'source_at_registration'
			},
			onError: () => {
				if (form.errors.email || form.errors.first_name || form.errors.last_name) {
					currentStep.value = 'one'
				}
				if (form.errors.phone || form.errors.password || form.errors.password_confirmation) {
					currentStep.value = 'two'
				}
				if (form.errors.turnstile_response) {
					if (currentStep.value === 'two') {
						// Reset the turnstile response error, so that it is now shown immediately when the Turnstile is still running.
						form.errors.turnstile_response = ''
					}

					currentStep.value = 'three'
				}
			},
		})
}

async function submit() {
	if (currentStep.value === 'one') {
		currentStep.value = 'two'
		return
	}

	if (currentStep.value === 'two') {
		register()
	}

	if (currentStep.value === 'three') {
		register()
	}

	if (currentStep.value === 'source_at_registration') {
		sourceAtRegistrationForm.post(route('captainjet.web.source-at-registration.store'), {
			preserveScroll: true,
			onSuccess: () => {
				close()
				form.reset()
				currentStep.value = 'one'
				properties.value?.onSuccess?.()
			},
			onError: () => {
				//
			},
		})
	}
}

const dialogTitle = computed(() => {
	return match(currentStep.value, {
		one: 'Sign up',
		source_at_registration: 'How do you know us?',
		default: 'Welcome',
	})
})

const buttonLabel = computed(() => {
	return match(currentStep.value, {
		two: 'Sign up',
		default: 'Continue',
	})
})

const canProceed = computed(() => {
	return match(currentStep.value, {
		one: form.first_name !== '' && form.last_name !== '' && form.email !== '',
		two: form.phone.text !== '' && form.password !== '' && form.password_confirmation !== '',
		three: form.turnstile_response !== '',
		default: true,
	})
})

onMounted(async () => {
	const { data } = await useFetch<string>('https://ip2c.org/self') // takes sometime to detect the location and return the iso2 code!
	const userIso2 = data.value?.split(';')?.[1]?.toLocaleLowerCase()
	const foundCountry = countries.find((c) => c.iso2 === userIso2)

	if (foundCountry) {
		form.phone.iso2 = foundCountry.iso2
		form.phone.dialCode = foundCountry.dialCode
	}
})

const cloudflareTurnstileSiteKey = import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE_KEY as string

const isCloudflareTurnstileError = ref(false)
const isCloudflareTurnstileUnsupported = ref(false)
</script>

<template>
	<base-dialog
		:show="isOpen"
		:title="dialogTitle"
		title-size="lg"
		@close="close()"
	>
		<template v-if="currentStep === 'two'" #back>
			<button
				class="absolute left-6 flex items-center justify-center space-x-2 text-sm font-semibold text-blue-200 hover:text-blue-300 focus-visible:ring-2 focus-visible:ring-turquoise-600"
				type="button"
				@click="currentStep = 'one'"
			>
				<icon-chevron-narrow-left class="w-3.5" />
				<span>Back</span>
			</button>
		</template>

		<form @submit.prevent="submit">
			<div class="pb-6">
				<div class="relative px-6">
					<!-- First step -->
					<transition
						enter-active-class="transition ease-out duration-500 relative z-10"
						enter-from-class="opacity-0 -translate-x-1"
						enter-to-class="opacity-100 translate-x-0"
						leave-active-class="ease-in duration-0 absolute z-0 inset-0"
						leave-from-class="opacity-100 translate-x-0"
						leave-to-class="opacity-0 -translate-x-1"
					>
						<div v-if="currentStep === 'one'">
							<div class="space-y-4">
								<BaseInput
									id="firstname"
									v-model="form.first_name"
									autocomplete="given-name"
									name="firstname"
									label="First name"
									type="text"
									:error="form.errors.first_name"
									:clearable="form.first_name !== ''"
									@clear="form.first_name = ''; form.clearErrors('first_name')"
								/>

								<BaseInput
									id="lastname"
									v-model="form.last_name"
									autocomplete="family-name"
									name="lastname"
									label="Last name"
									type="text"
									:error="form.errors.last_name"
									:clearable="form.last_name !== ''"
									@clear="form.last_name = ''; form.clearErrors('last_name')"
								/>

								<BaseInput
									id="email"
									v-model="form.email"
									name="email"
									label="Email"
									type="email"
									:error="form.errors.email"
									:clearable="form.email !== ''"
									@clear="form.email = ''; form.clearErrors('email')"
								/>
							</div>

							<p class="mt-6 text-xs leading-5 tracking-tighter text-blue-500">
								By creating your account you acknowledge that you have read and understand the
								<a
									href="#"
									class="font-medium underline hover:text-blue-400 focus-visible:ring-2 focus-visible:ring-turquoise-600"
								>terms
									of the CaptainJet Privacy Policy</a>
								and that you give your consent for CaptainJet to process sensitive personal data that you may decide to
								provide, as set out in the CaptainJet Privacy Policy.
							</p>
						</div>
					</transition>

					<!-- Second step -->
					<transition
						enter-active-class="transition ease-out duration-500 relative z-10"
						enter-from-class="opacity-0 translate-x-1"
						enter-to-class="opacity-100 translate-x-0"
						leave-active-class="ease-in duration-0 absolute z-0 inset-0"
						leave-from-class="opacity-100 translate-x-0"
						leave-to-class="opacity-0 translate-x-1"
					>
						<div v-if="currentStep === 'two'">
							<div class="space-y-4">
								<InputPhoneNumber
									id="phone"
									v-model:text="form.phone.text"
									v-model:e164="form.phone.e164"
									v-model:iso2="form.phone.iso2"
									v-model:dial-code="form.phone.dialCode"
									v-model:formatted-text="form.phone.formattedText"
									name="phone"
									:error="form.errors.phone"
									@clear="form.phone.text = ''; form.clearErrors('phone')"
								/>

								<BaseInput
									id="password"
									v-model="form.password"
									autocomplete="new-password"
									name="password"
									label="Password"
									type="password"
									:error="form.errors.password"
									:clearable="form.password !== ''"
									@clear="form.password = ''; form.clearErrors('password')"
								/>

								<BaseInput
									id="confirm"
									v-model="form.password_confirmation"
									autocomplete="current-password"
									name="confirm"
									label="Confirm"
									type="password"
									:error="form.errors.password_confirmation"
									:clearable="form.password_confirmation !== ''"
									@clear="form.password_confirmation = ''; form.clearErrors('password_confirmation')"
								/>
							</div>

							<p class="mt-4 text-sm tracking-tighter text-blue-500">
								Please verify your phone number and choose your password.
							</p>
						</div>
					</transition>

					<!-- Third step -->
					<transition
						enter-active-class="transition ease-out duration-500 relative z-10"
						enter-from-class="opacity-0 translate-x-1"
						enter-to-class="opacity-100 translate-x-0"
						leave-active-class="ease-in duration-0 absolute z-0 inset-0"
						leave-from-class="opacity-100 translate-x-0"
						leave-to-class="opacity-0 translate-x-1"
					>
						<div v-if="currentStep === 'three'">
							<div class="flex flex-row justify-center space-y-4">
								<VueTurnstile
									v-model="form.turnstile_response"
									:site-key="cloudflareTurnstileSiteKey"
									@error="isCloudflareTurnstileError = true"
									@unsupported="isCloudflareTurnstileUnsupported = true"
								/>
							</div>

							<div v-if="form.errors.turnstile_response ?? false" class="mt-4 flex items-center justify-center space-x-2 text-sm font-medium text-danger-500">
								<IconExclamationCircle class="size-3.5 shrink-0" />
								<span>{{ form.errors.turnstile_response }}</span>
							</div>

							<p class="mt-4 text-center text-sm tracking-tighter text-blue-500">
								<span v-show="form.turnstile_response === '' && !isCloudflareTurnstileError && !isCloudflareTurnstileUnsupported">
									Checking your browser...
								</span>
								<span v-show="form.turnstile_response !== '' && !isCloudflareTurnstileError && !isCloudflareTurnstileUnsupported">
									Click continue to proceed.
								</span>
								<span v-show="isCloudflareTurnstileError">
									An error occurred while verifying your browser. <br /><br />
								</span>
								<span v-show="isCloudflareTurnstileUnsupported">
									Your browser is unsupported. <br /><br />
								</span>
								<span v-show="isCloudflareTurnstileError || isCloudflareTurnstileUnsupported">
									Please contact a broker via <a href="mailto:charter@captainjet.com">charter@captainjet.com</a> or via the chat-button at the bottom right.
								</span>
							</p>
						</div>
					</transition>

					<!-- Source at registration step -->
					<!-- First step -->
					<transition
						enter-active-class="transition ease-out duration-500 relative z-10"
						enter-from-class="opacity-0 -translate-x-1"
						enter-to-class="opacity-100 translate-x-0"
						leave-active-class="ease-in duration-0 absolute z-0 inset-0"
						leave-from-class="opacity-100 translate-x-0"
						leave-to-class="opacity-0 -translate-x-1"
					>
						<div v-if="currentStep === 'source_at_registration'">
							<BaseInput
								id="source_at_registration"
								v-model="sourceAtRegistrationForm.source_at_registration"
								name="source_at_registration"
								label="Optional"
								placeholder="E.g. referral, event, social media..."
								type="textarea"
								:error="sourceAtRegistrationForm.errors.source_at_registration"
								:clearable="sourceAtRegistrationForm.source_at_registration !== ''"
								@clear="sourceAtRegistrationForm.source_at_registration = ''; sourceAtRegistrationForm.clearErrors('source_at_registration')"
							/>
						</div>
					</transition>
				</div>
			</div>

			<!-- Submission -->
			<div class="px-6 pb-8">
				<base-button
					type="submit"
					size="lg"
					variant="primary"
					class="w-full font-display text-lg font-bold tracking-tight"
					:disabled="!canProceed"
					:loading="form.processing || sourceAtRegistrationForm.processing"
					:text="buttonLabel"
				/>
			</div>
		</form>
	</base-dialog>
</template>

<script setup lang="ts">
import { parseDateTime } from '~/ts/utils/datetime'
import Document from '@/components/PDF/Document.vue'
import LegsTable from '@/components/PDF/LegsTable.vue'
import Heading from '@/components/PDF/Heading.vue'
import HeadingAddress from '@/components/PDF/HeadingAddress.vue'
import AircraftTable from '@/components/PDF/AircraftTable.vue'
import { useProperty } from '@/composables/useProperty'

type QuotationData = Domain.BookingCaptainJet.Data.QuotationData
type ContractMetaData = Domain.BookingCaptainJet.Data.ContractMetaData
type PdfUserData = SupportCaptainJet.Data.PdfUserData

const $props = defineProps<{
	quotation: QuotationData
	meta: ContractMetaData
	signatoryName: string
	signatorySignature: string | null
	user: PdfUserData
}>()

interface Term {
	from: number
	to?: number
	rate: number
}

const company = useProperty('captainjet.company')

function getTermDescription(term: Term): string {
	const toLiteral = (count: number = 0) => count > 48 ? `${count / 24} days` : `${count} hours`
	const literalFrom = toLiteral(term.from)
	const literalTo = toLiteral(term.to)

	if (term.from === 0 && !term.to) {
		return 'upon contract signature'
	}

	if (term.to === 0) {
		return `within ${literalFrom} prior to departure`
	}

	// We replace the first occurrence of hours/days to avoid repetition, easier
	// than computing a new variable for each case for both from and to.
	return `within ${literalFrom} to ${literalTo} prior to departure`
}

const terms = $props.meta.cancellation_terms!
	.slice()
	.map((term) => ({ from: Number(term.from), to: Number(term.to), rate: Number(term.rate) }))
	.sort((a, b) => (a.rate ?? 0) - (b.rate ?? 0)) // sorting by rate instead of date because Chrome behaves differently than FF
	.map((term) => `Cancellation fee ${getTermDescription(term)}: ${term.rate}% of the agreed signed Charter Agreement.`)
</script>

<template>
	<Document :title="`Flight brokerage agreement N°${quotation.booking?.reference ?? '<preview>'}`" :user="user">
		<Heading>
			<HeadingAddress
				label="To"
				:title="quotation.billing_address?.company_name ?? `${quotation.billing_address?.first_name} ${quotation.billing_address?.last_name}`"
				subtitle="The Customer"
			>
				<span v-if="quotation.billing_address?.company_name">
					{{ quotation.billing_address?.first_name }}
					{{ quotation.billing_address?.last_name }}
				</span>
				<span class="block whitespace-pre-line" v-text="quotation.billing_address?.company_address" />
				<span class="block" v-text="quotation.billing_address?.vat_number" />
			</HeadingAddress>
			<HeadingAddress
				label="From"
				:title="company.name"
				subtitle="The Agent"
			>
				{{ company.address }} <br />
				{{ company.postal_code }} {{ company.city }}<br />
				{{ company.country }} <br />
			</HeadingAddress>
			<HeadingAddress
				label="In partnership with"
				:title="quotation.operator?.name"
				subtitle="The Carrier"
			/>
		</Heading>

		<PDFSection>
			<p>
				The present agreement is a binding contract between
				{{ company.name }} hereinafter "the Agent" and
				{{ `${quotation.billing_address?.first_name} ${quotation.billing_address?.last_name}` }} hereinafter "the Customer"
				in which the Customer instructs the Agent to sign a
				charter contract / contract of carriage on his behalf (the "Charter Agreement").
			</p>
			<p>
				The present agreement is subject to <a href="https://www.fly7.ch/wp-content/uploads/2022/02/Fly-7-Conditions-Flight-Brokerage-.pdf">CaptainJet's Terms and Conditions for Aircraft Charter Brokerage</a> and the Customer's rights and obligations under the Charter Agreement to the Carrier's conditions of carriage, which are available on the
				Carrier's website and are in line with industry standards.
			</p>
		</PDFSection>

		<PDFSection title="1. Flight Details">
			<p>
				According to the Customer's instructions, the Agent shall endeavour and is authorised to arrange with the Carrier the
				following trip (all times in local time):
			</p>
		</PDFSection>
		<PDFSection>
			<LegsTable :legs="quotation.legs ?? []" :user="$props.user" />
		</PDFSection>
		<PDFSection>
			<AircraftTable
				:aircraft="quotation.aircraft!"
				:pets-on-board="meta.pets_on_board!"
				:luggage-limit="meta.luggage_limit!"
			/>
		</PDFSection>
		<PDFSection>
			<p>
				Routing, schedule, and price are subjected to airport slots, parking, traffic rights and permissions.
			</p>
		</PDFSection>
		<PDFSection title="2. flight price">
			<p>
				The Agent and the Customer both agreed on the following flight price:
				<span class="font-bold" v-text="quotation.display_price_formatted" />
				(including VAT and application taxes).
			</p>
			<p>
				The flight price takes into account the number of passengers indicated above. If the number of
				passengers changes,
				the price will be recalculated (airport tax, catering, ...).
			</p>
			<p>
				The Customer acknowledges that the flight price does not include: de-icing, special catering
				requests, VIP FBOs, ground
				transportation, satellite and internet use, visa and insurance, change of destination, etc…Additional
				requests are invoiced
				separately. The payment must be received 24H before the flight.
			</p>
			<p>The Customer furthermore acknowledges that the Agent will use the funds received from the Customer to prepay the Carrier, in accordance with the terms of the Charter Agreement.</p>
			<p>
				Payment terms and conditions:
			</p>
			<p>
				In case of lack of effective payments within the deadlines, the Agent reserves the right to
				automatically terminate the
				charter contract without any notice and compensation.
			</p>
			<p class="block font-bold">
				The balance must be paid to the Agent before {{ parseDateTime(meta.payment_due_at!, { dateFormat: 'MMMM D, YYYY', ignoreTimezone: true }).date }}.
			</p>
		</PDFSection>
		<PDFSection title="3. Obligation of the parties">
			<p>
				The Customer acknowledges that the Carrier's crew will be in complete charge and control of the
				aircraft at all times. For
				safety reasons, the pilot may terminate or divert the flight, may reduce the number of passengers or
				take any other safety
				measures.
			</p>
			<p>
				The Customer is responsible for ensuring that their passengers have the correct travel documentation
				and comply with all
				laws, regulations of any countries to be flown. The Customer must ensure that their passengers
				respect the Carrier's
				luggage and dangerous goods policy. The Customer is responsible to give the appropriate information
				to their passengers
				(schedule, FBO…) if the Customer has a delay of more than 2 hours, the carrier has the right to
				terminate the charter
				contract without any notice and compensation
			</p>
			<p>
				This agreement is subject to traffic rights and airport authorizations. If the aircraft becomes
				unavailable, the Carrier will
				provide a similar category aircraft from his fleet or from another carrier's fleet.
			</p>
			<p>
				Neither the carrier, nor the Agent can be responsible for the following reasons: technical failures of
				the aircraft, fuel supply,
				airports strike, bad weather conditions, or any case of force majeure. If the carrier can not perform
				the flight for any of these
				reasons, the amount paid by the Customer will be reimbursed without any compensation.
			</p>
		</PDFSection>

		<PDFSection title="4. Cancellation policy">
			<p>
				Applicable cancellation fees:
			</p>
			<ul class="list-outside list-disc space-y-1 pl-4">
				<li v-for="term in terms" :key="term" v-text="term" />
			</ul>
			<p>
				These terms reflect the Carrier's cancellation policy and take into account, in particular, the costs incurred for positioning the aircraft, loss of revenue, etc.
			</p>
			<p>
				The Customer must notify the Agent of any cancellation in writing.
			</p>
			<p v-if="meta.pertains_empty_leg" class="border-l-2 border-l-blue-500 pl-2">
				For clarity, this flight is classified as an Empty Leg Flight. Due to the provision of discounted rates, the Operator reserves the right to cancel the flight anytime before the planned departure. In the event of such a cancellation by the Operator, a refund will be processed for the Client. The set itinerary can be adjusted based on
				modifications to the primary charter flight.
			</p>
		</PDFSection>

		<PDFSection title="5. Applicable law">
			<p>
				The Customer declares to accept the carrier's terms and conditions.
			</p>
			<p>
				The present agreement is governed by Swiss law and
				any dispute or claim arising out or in connection with it shall be submitted to the exclusive
				jurisdiction of the Courts of
				Lausanne.
			</p>
		</PDFSection>

		<PDFSection :headless="true">
			<div class="flex py-5 tracking-tighter">
				<div class="w-1/2">
					<p class="font-medium">
						The Customer
					</p>
					<p>
						{{ quotation.billing_address?.first_name }}
						{{ quotation.billing_address?.last_name }}
					</p>
					<div class="flex h-20 items-center">
						<img v-if="quotation.signature_url" :src="quotation.signature_url" class="size-full" />
						<!-- <span v-else>(missing signature)</span> -->
					</div>
				</div>
				<div class="w-1/2">
					<p class="font-medium">
						{{ company.name }}
					</p>
					<p v-text="signatoryName" />

					<div class="h-20">
						<img v-if="signatorySignature" :src="signatorySignature" class="size-full object-contain" />
						<ContractSignature v-else class="mt-2 w-48" />
					</div>
				</div>
			</div>
		</PDFSection>
	</Document>
</template>

<script setup lang="ts">
import { router, useForm } from '@inertiajs/vue3'

import { useProperty } from '@/composables/useProperty'
import { match } from '~/ts/utils/match'
import { route } from '@/composables/route'
import { useRegisterDialog } from '~/ts/dialog'

const isOpen = computed(() => {
	const { isOpen } = useRegisterDialog()

	if (isOpen.value) {
		// If register dialog is open (for `source_at_registration`), do not open new challenge dialog.
		return false
	}

	return !!useProperty<string | null>('security.challenge').value
})

const type = useProperty<'sms' | 'email'>('security.challenge.type')
const target = useProperty<string>('security.challenge.target')
const form = useForm({ code: '' })

function obscurePhoneNumber(phonenumber?: string) {
	if (!phonenumber) {
		return '-'
	}

	const first = phonenumber.slice(0, 5)
	const last = phonenumber.slice(phonenumber.length - 2)
	const middle = phonenumber.slice(5, -2).replace(/\S/g, '*') // replace any non-whitespace char with *

	return first + middle + last
}

const viaCodeMessage = computed(() => match(type.value, {
	sms: `to ${obscurePhoneNumber(target.value)}`,
	default: `at ${target.value}`,
}))

const canProceed = computed(() => form.code.length === 6)

function submit() {
	form.post(route('captainjet.web.challenge.validate'), {
		onSuccess: () => form.reset(),
	})
}

function resend() {
	form.post(route('captainjet.web.challenge.resend'))
}

function onClose(force?: boolean) {
	if (force) {
		router.delete(route('captainjet.web.logout'))
	}
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		title="Verification code"
		title-size="lg"
		@close="onClose"
	>
		<form @submit.prevent="submit">
			<div class="flex flex-col items-center px-20">
				<icon-verification-code-email v-if="type === 'email'" class="size-12" />
				<icon-verification-code-phone v-if="type === 'sms'" class="size-12" />
				<p class="mt-5 text-center font-display text-2xl font-bold tracking-wide text-blue-500">
					Enter your <br />
					verification code
				</p>
				<div class="mt-7">
					<InputVerificationCode v-model="form.code" :error="form.errors.code" @submit="submit" />
				</div>
				<div class="mt-7">
					<p class="text-center text-blue-500">
						Please type the verification code we have sent you {{ viaCodeMessage }}.
					</p>
				</div>
				<div class="inline-flex items-center space-x-2 py-7 text-blue-500">
					<span>Haven't received it?</span>
					<button class="flex items-baseline font-medium text-turquoise-600" @click.prevent="resend">
						<span>Resend code</span>
						<IconChevronArrowRight class="ml-2 w-3.5" />
					</button>
				</div>
			</div>

			<!-- Submission -->
			<div class="px-6 pb-6">
				<BaseButton
					variant="primary"
					type="submit"
					size="lg"
					class="w-full font-display text-lg"
					:disabled="!canProceed"
					:loading="form.processing"
				>
					Verify
				</BaseButton>
			</div>
		</form>
	</BaseDialog>
</template>

<script setup lang="ts">
import { router } from '@inertiajs/vue3'

import type { CreateLegData } from '~/ts/legs'

import { route } from '@/composables/route'
import { getAirportByIcao } from '~/ts/airports'
import { useHistory } from '@/composables/useHistory'
import { getReturnDate, parse } from '~/ts/utils/datetime'
import mykonosImg1366 from '~/images/captainjet/marketing/mykonos/1366w.webp'
import mykonosImg1536 from '~/images/captainjet/marketing/mykonos/1536w.webp'
import mykonosImg1920 from '~/images/captainjet/marketing/mykonos/1920w.webp'
import mykonosImg2732 from '~/images/captainjet/marketing/mykonos/2732w.webp'
import mykonosImg3840 from '~/images/captainjet/marketing/mykonos/3840w.webp'
import mykonosImg800w from '~/images/captainjet/marketing/mykonos-sm/800w.webp'
import mykonosImg1600w from '~/images/captainjet/marketing/mykonos-sm/1600w.webp'

type BookingData = Domain.BookingCaptainJet.Data.BookingData

const $props = defineProps<{
	booking?: BookingData
}>()

const legs = useHistory<CreateLegData[]>('index:legs', [{
	departure_date: getReturnDate().format(),
	female_count: 0,
	male_count: 1,
	children_count: 0,
	baby_count: 0,
	pet_count: 0,
}])

const wrapper = ref<HTMLDivElement>()
const airport = ref<string>()
const placeholder = 'your destination'

onMounted(async () => {
	// When a `from` with an ID is given as a parameter, the back-end returns
	// the corresponding booking (if it exists and the user is allowed to
	// see it). We then fill the search fields with that booking.
	if ($props.booking?.legs) {
		legs.value = await Promise.all<Promise<CreateLegData>[]>($props.booking.legs.map(async (leg) => ({
			departure_date: leg.departs_at_local_time,
			departure_airport: await getAirportByIcao(leg.departure_airport?.icao_code),
			arrival_airport: await getAirportByIcao(leg.arrival_airport?.icao_code),
			female_count: leg.female_count,
			male_count: leg.male_count,
			children_count: leg.children_count,
			baby_count: leg.baby_count,
			pet_count: leg.pet_count,
		})))
	}
})

useHead({
	title: () => `Reach ${airport.value ?? placeholder} in a few clicks`,
})

watch(() => legs.value?.at(0)?.arrival_airport, async (newAirport, previousAirport) => {
	if (newAirport?.icao_code === previousAirport?.icao_code) {
		return
	}

	const result = await getAirportByIcao(newAirport?.icao_code)

	airport.value = result?.served_city
}, {
	deep: true,
	immediate: true,
})

function createBooking() {
	router.post(route('captainjet.web.booking.store'), {
		legs: legs.value.map((leg) => ({
			...leg,
			departure_airport: leg.departure_airport?.icao_code,
			arrival_airport: leg.arrival_airport?.icao_code,
			departure_date: parse(leg.departure_date).format(),
		})),
	})
}
</script>

<template>
	<div id="booking" ref="wrapper" class="relative bg-blue-600">
		<div class="relative mx-auto">
			<div class="absolute h-[380px] w-full overflow-hidden lg:h-[510px]">
				<img
					class="hidden size-full object-cover object-top lg:block"
					:srcSet="`
						${mykonosImg1366} 1366w,
						${mykonosImg1536} 1536w,
						${mykonosImg1920} 1920w,
						${mykonosImg2732} 2732w,
						${mykonosImg3840} 3840w,
					`"
					sizes="100vw"
					alt="London at sunset"
				/>
				<img
					class="-mt-3 h-[420px] w-full max-w-none object-cover object-left xs:h-[380px] lg:hidden"
					:srcSet="`
						${mykonosImg1600w} 1600w,
						${mykonosImg800w} 800w,
					`"
					sizes="100vw"
					alt="London at sunset"
				/>
				<div class="bg-fade-overlay absolute -left-3.5 -top-4 h-[400px] w-[620px] max-w-[150%] lg:hidden" />
				<!-- Possible blur to stack on top of image at left-side. Evaluate on a per-image basis. -->
				<!-- <div class="bg-fade-overlay-lg absolute -left-3.5 -top-4 hidden h-[584px] w-1/2 lg:block" /> -->
				<div class="bg-blur-overlay absolute bottom-0 left-0 h-16 w-full lg:h-24" />
			</div>

			<transition
				appear
				mode="out-in"
				enter-active-class="duration-500 transition-all"
				enter-from-class="opacity-0 -translate-x-2"
				enter-to-class="opacity-100 translate-x-0"
				leave-active-class="duration-500 transition-all"
				leave-from-class="opacity-100 translate-x-0"
				leave-to-class="opacity-0 -translate-x-2"
			>
				<div class="pb-16 pt-28 lg:pt-[136px]">
					<MarketingContainer class="relative z-10 flex items-center px-6">
						<h1 class="font-inter text-white">
							<div class="flex items-center text-[32px] font-extrabold leading-10 lg:text-6xl lg:leading-[78px]">
								<span class="hidden lg:inline">Reach&nbsp;</span>
								<div class="-mb-1 mt-1 hidden gap-px lg:flex">
									<marketing-split-flap
										v-for="(char, i) in Array.from(airport ?? placeholder)"
										:key="i"
										:initial-char="char === ' ' ? char : String.fromCharCode(97 + Math.round(Math.random() * 25))"
										:target-char="char"
										:height="65"
										:min-width="38"
										split-color="transparent"
										flap-color="rgb(26 54 80 / 80%)"
										:border-radius="7"
										:initial-delay="0"
										:text-v-offset="4"
									/>
								</div>
								<span class="lg:hidden">Reach {{ airport ?? placeholder }}</span>
							</div>
							<span class="text-[32px] leading-10 lg:text-5xl lg:leading-[66px]">in a few clicks.</span>
						</h1>
					</MarketingContainer>

					<MarketingContainer class="mt-16 lg:mt-28">
						<LegsInput v-model="legs" @search="createBooking" />
					</MarketingContainer>
				</div>
			</transition>
		</div>
	</div>
</template>

<style lang="postcss">
.bg-fade-overlay {
	background: linear-gradient(
		74.88deg,
		#1a3550 14.62%,
		rgba(100, 167, 182, 0) 69.8%
	);
}
.bg-fade-overlay-lg {
	background: linear-gradient(
		78.26deg,
		#1a3650 -6.27%,
		rgba(26, 54, 80, 0) 58.93%
	);
}
.bg-blur-overlay {
	background: linear-gradient(
		0deg,
		#1a3550 0%,
		rgba(26, 53, 80, 0.95) 25%,
		rgba(26, 53, 80, 0.4) 75%,
		rgba(26, 53, 80, 0) 100%
	);
	backdrop-filter: blur(20px);
}
</style>
